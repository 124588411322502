import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import Fade from '@mui/material/Fade';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faTwitter, faMedium, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { ContactModal } from "@petzold-io/mui-contact-modal";

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import ToggleColorModeIcon from './ToggleColorModeIcon'

const Icons = {
  github: faGithub,
  twitter: faTwitter,
  medium: faMedium,
  linkedin: faLinkedin,
};

function SocialIcon({ name, link }) {
  return (
    <Box
      sx={{
        marginRight: "2rem",
        display: "inline",
        color: "#a9a9a9",
        "& a:-webkit-any-link": {
          color: "#a9a9a9",
        },
      }}
    >
      <a href={link} alt={name}>
        <FontAwesomeIcon icon={Icons[name]} size="2xl" />
      </a>
    </Box>
  );
}

const SocialLinks = React.forwardRef(({ showFade }, ref) => {
  return (
    <Fade
      in={showFade}
      timeout={{
        appear: 2000,
        enter: 2000,
      }}
    >
      <Box
        ref={ref}
        sx={{
          marginTop: "2rem",
          textAlign: "center",
        }}
      >
        <SocialIcon name="github" link="https://github.com/dpetzold" />
        <SocialIcon name="medium" link="https://medium.com/@dpetzold" />
        <SocialIcon name="twitter" link="https://twitter.com/derrickpetzold" />
        <SocialIcon
          name="linkedin"
          link="https://linkedin.com/derrickpetzold"
        />
      </Box>
    </Fade>
  );
});

const HeroText = React.forwardRef(({ onEntered }, ref) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const textAlign = smallScreen ? "center" : "left";

  return (
    <Slide
      direction="right"
      in={true}
      timeout={{
        appear: 1000,
        enter: 2000,
      }}
      onEntered={onEntered}
    >
      <Box
        ref={ref}
        sx={{
          fontSize: "3.5rem",
          fontWeight: 700,
          lineHeight: "1.2",
          textAlign: textAlign,
          fontFamily: "Montserrat, sans-serif"
        }}
      >
        <Box
          sx={{
            fontFamily: "Montserrat, sans-serif"
          }}
        >
          Hi, my name is{" "}
          <Box component="span" sx={{ color: "text.highlight" }}>
            Derrick.
          </Box>
        </Box>
        <Box>Welcome to my home on the web.</Box>
      </Box>
    </Slide>
  );
});

function Contact() {
  const [show, setShow] = useState(false);
  const handleClose = () => { setShow(false) };
  const handleShow = () => { setShow(true) };
  const theme = useTheme();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('open')) {
      setShow(searchParams.get('open') === "true")
    }
  }, [setShow])

  return (
    <Box
      sx={{
        marginRight: "auto",
        marginLeft: "auto",
        textAlign: "center",
        padding: "2rem",
      }}
    >
      <Button variant="contained" onClick={handleShow} onKeyPress={handleShow}>
        Contact me
      </Button>
      <ContactModal
        apiEndpoint={process.env.REACT_APP_API_ENDPOINT}
        dialogOpen={show}
        closeDialog={handleClose}
        theme={theme}
      />
    </Box>
  );
}

function HeroContent({ children }) {
  return (
    <Box
      sx={{
        display: "flex",
        padding: "2rem",
        flexDirection: "column",
      }}
    >
      {children}
    </Box>
  );
}

export default function HeroPage() {
  const [showFade, setShowFade] = React.useState(false);

  return (
    <Box
      sx={{
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "column",
        display: "flex",
        minHeight: "100vh",
        bgcolor: 'background.default',
        color: 'text.primary',
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <ToggleColorModeIcon />
      </Box>
      <HeroContent>
        <HeroText onEntered={() => setShowFade(true)} />
        <SocialLinks showFade={showFade} />
        <Contact />
      </HeroContent>
        <Box>
        </Box>
    </Box>
  );
}
